import React from 'react';
import Link from 'next/link';

const PopularCourses = () => {
    const [courses, setCourses] = React.useState([]);

    // Example of static data, replace this with API call if needed
    React.useEffect(() => {
        const fetchCourses = async () => {
            // Simulate fetching data
            const courseData = [
                {
                    id: 1,
                    image: "/images/courses/courses1.jpg",
                    price: "$39",
                    title: "The Data Science Course 2020: Complete Data Science Bootcamp",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
                    lessons: 15,
                    students: 145,
                    rating: 4.5,
                    ratingCount: 109,
                },
                {
                    id: 2,
                    image: "/images/courses/courses2.jpg",
                    price: "$49",
                    title: "Java Programming MasterclassName for Software Developers",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
                    lessons: 20,
                    students: 100,
                    rating: 4.7,
                    ratingCount: 78,
                },
                {
                    id: 2,
                    image: "/images/courses/courses3.jpg",
                    price: "$49",
                    title: "Java Programming MasterclassName for Software Developers",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
                    lessons: 20,
                    students: 100,
                    rating: 4.7,
                    ratingCount: 78,
                },
                {
                    id: 2,
                    image: "/images/courses/courses4.jpg",
                    price: "$49",
                    title: "Java Programming MasterclassName for Software Developers",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
                    lessons: 20,
                    students: 100,
                    rating: 4.7,
                    ratingCount: 78,
                },
                // Add more courses as needed
            ];
            setCourses(courseData);
        };

        fetchCourses();
    }, []);

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStar;
        return (
            <>
                {Array(fullStars).fill(<i className='bx bxs-star' key={`full-${fullStars}`}></i>)}
                {halfStar > 0 && <i className='bx bxs-star-half' key={`half-${halfStar}`}></i>}
                {Array(emptyStars).fill(<i className='bx bx-star' key={`empty-${emptyStars}`}></i>)}
            </>
        );
    };

    return (
        <div className="courses-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Go At Your Own Pace</span>
                    <h2>Our Popular Courses</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div className="row">
                    {courses.map((course) => (
                        <div key={course.id} className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image">
                                    <Link href="/single-courses-2">
                                        <a className="d-block image">
                                            <img src={course.image} alt="image" />
                                        </a>
                                    </Link>
                                    <a href="#" className="fav">
                                        <i className="flaticon-heart"></i>
                                    </a>
                                    <div className="price shadow">{course.price}</div>
                                </div>
                                <div className="courses-content">
                                    <div className="rating">
                                        {renderStars(course.rating)}
                                        <div className="rating-total">
                                            {course.rating} ({course.ratingCount} ratings)
                                        </div>
                                    </div>
                                    <h3>
                                        <Link href="/single-courses-2">
                                            <a>{course.title}</a>
                                        </Link>
                                    </h3>
                                    <p>{course.description}</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li>
                                            <i className='flaticon-agenda'></i> {course.lessons} Lessons
                                        </li>
                                        <li>
                                            <i className='flaticon-people'></i> {course.students} Students
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courses-info">
                    <p>Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <Link href="/profile-authentication"><a>Join Free Now</a></Link>.</p>
                </div>
            </div>
        </div>
    );
}

export default PopularCourses;
