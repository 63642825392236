import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import EventTile from '../events/EventTile';

const UpcomingEvents = () => {
    const [events, setEvents] = useState([
        {
            id: 1,
            title: "Global Conference on Business Management",
            imageUrl: "/images/events/health-img1.jpg",
            date: "Wed, 20 May, 2020",
            location: "Vancover, Canada",
            progress: 25,
            slotsLeft: 213,
        },
        {
            id: 2,
            title: "International Conference on Teacher Education",
            imageUrl: "/images/events/health-img2.jpg",
            date: "Tue, 19 May, 2020",
            location: "Sydney, Australia",
            progress: 50,
            slotsLeft: 100,
        },
        {
            id: 3,
            title: "International Conference on Special Needs Education",
            imageUrl: "/images/events/health-img3.jpg",
            date: "Mon, 18 May, 2020",
            location: "Istanbul, Turkey",
            progress: 85,
            slotsLeft: 35,
        },
    ]);

    return (
        <div className="events-area bg-image bg-eee8df pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Events</span>
                    <h2>Our Upcoming Events</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div className="row">
                    {events.map(event => (
                        <EventTile key={event.id} event={event} /> // Use EventTile component
                    ))}
                </div>
            </div>
        </div>
    );
}

export default UpcomingEvents;